import { graphql } from 'gatsby';
import moment from 'moment';
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { Container, Container50 } from '../components/Layout/containers';
import { MainSection100, MainSection50 } from '../components/Layout/sections';
import NewLayout from '../components/new_layout';
import Seo from '../components/seo';
import Certifications from '../components/v2023/Certifications/certifications';
import Identity from '../components/v2023/Identity/identity';
import CardEvent from '../components/v2023/UI/Card/card-event';
import { CarouselTouch } from '../components/v2024/Carousel/carousel-touch';

const Events = ({ data, pageContext }) => {
	const lang = pageContext.langKey;
	const page = data.page;
	const prev_events = data.events.edges.filter((e) => moment(e.node.date_start).isBefore(new Date()));
	const next_events = data.events.edges
		.filter((e) => moment(e.node.date_start).isAfter(new Date()))
		.sort((a, b) => new Date(a.node.date_start) - new Date(b.node.date_start));
	const events = next_events.concat(prev_events);
	const eventsByTecalis = data.eventsByTecalis.edges;
	const principal = events[0].node;
	const highlights = events.slice(1, 4);

	const [eventSize, setEventSize] = useState(9);
	const [eventListed, setEventListed] = useState(events.slice(4, 4 + eventSize));

	const showMoreEvents = async () => {
		let s = eventSize;

		if (s < events.length) {
			s += 9;
			setEventSize(s);
			setEventListed(events.slice(4, 4 + s));
		}
	};

	return (
		<NewLayout pageContext={pageContext}>
			<Seo
				lang={lang}
				title={page.seo.title}
				description={page.seo.meta_description}
				image={page.seo?.image_2?.localFile?.publicURL}
				translates={pageContext.translates}
			/>

			<main className="main">
				<MainSection50 sectionType="white" sectionColor="green-sm" sectionSize="lg">
					<Container title>
						<h1 lang={lang} className="merriweather">
							{page.title}
						</h1>
					</Container>
					<Container flexStart>
						<Container50 animation="fade-right">
							<CardEvent event={principal} principal={true} lang={lang} customClass={'card--big'} />
						</Container50>
						<Container50 animation="fade-left">
							{highlights.map((event, index) => (
								<CardEvent key={index} event={event.node} lang={lang} horizontal={true} />
							))}
						</Container50>
					</Container>
				</MainSection50>

				{eventsByTecalis.length > 0 && (
					<MainSection100 sectionColor="purple" sectionType="color">
						<Container>
							<ReactMarkdown children={page.by_tecalis_underline} rehypePlugins={[rehypeRaw]} />
							<CarouselTouch
								lang={lang}
								slides={eventsByTecalis}
								showArrows={true}
								showIndicators={true}
								useCardEvent
							/>
						</Container>
					</MainSection100>
				)}

				<MainSection100>
					<Container>
						<h2>{page.all_events}</h2>
						<div className="grid-lg-3">
							{eventListed.map((event, index) => (
								<CardEvent key={index} event={event.node} showDescription={true} lang={lang} />
							))}
						</div>

						{eventListed.length < events.length - 4 && (
							<div>
								<button className="button button--xl button--secondary" onClick={showMoreEvents}>
									{page.view_more}
								</button>
							</div>
						)}
					</Container>
				</MainSection100>

				<Certifications />

				<Identity lang={lang} />
			</main>
		</NewLayout>
	);
};

export default Events;

export const eventsQuery = graphql`
	query ($langKey: String) {
		page: strapi2023Event(locale: { eq: $langKey }) {
			seo {
				title
				meta_description
				image_2 {
					localFile {
						publicURL
					}
				}
			}
			title
			by_tecalis
			by_tecalis_underline
			all_events
			view_more
		}
		events: allStrapiEvents(
			filter: { locale: { eq: $langKey }, by_tecalis: { eq: false } }
			sort: { fields: [date_start], order: [DESC] }
		) {
			edges {
				node {
					seo {
						meta_description
					}
					title
					description
					date_start
					date_end
					image_preview {
						name
						alternativeText
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1125, quality: 100, placeholder: BLURRED, formats: [WEBP])
							}
						}
					}
					location {
						city
					}
					url
				}
			}
		}

		eventsByTecalis: allStrapiEvents(
			filter: { locale: { eq: $langKey }, by_tecalis: { eq: true } }
			sort: { fields: [date_start], order: [DESC] }
		) {
			edges {
				node {
					seo {
						meta_description
					}
					title
					description
					date_start
					date_end
					image_preview {
						name
						alternativeText
						localFile {
							childImageSharp {
								gatsbyImageData(width: 930, quality: 100, placeholder: BLURRED, formats: [WEBP])
							}
						}
					}
					location {
						city
					}
					url
				}
			}
		}
	}
`;
