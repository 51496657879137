import React from "react";

export const MainSection100 = ({sectionId = '', sectionColor, sectionType, slim = false, noPadding = false, noPaddingBottom = false, children}) => {
    const sectionClass = ['main__section main__section--100']
    mountSectionTypeColor(sectionColor, sectionType, sectionClass)
    return (
        <div id={sectionId} className={sectionClass.join(' ')}>
            {children}
        </div>
    )
}

export const MainSection50 = ({sectionId = '', sectionColor, sectionType, sectionSize, children}) => {
    const sectionClass = ['main__section main__section--50']
    mountSectionTypeColor(sectionColor, sectionType, sectionClass)
    if (sectionSize) {
        sectionClass.push(`main__section--50--${sectionSize}`)
    }
    return (
        <div id={sectionId} className={sectionClass.join(' ')}>
            {children}
        </div>
    )
}

const mountSectionTypeColor = (sectionColor, sectionType, sectionClass) => {
    if (sectionColor && sectionType) {
        sectionClass.push(`${sectionType}-section ${sectionColor}`)
    } 
}