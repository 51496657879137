import React from "react";

export const Container = ({title = false, flexStart = false, children}) => {
    const containerClass = ['container']
    if (title) {
        containerClass.push('container--title')
    }
    if (flexStart) {
        containerClass.push('flex-start')
    }
    return (<div className={containerClass.join(' ')}>{children}</div>)
}

export const Container50 = ({textCenter = false, animation = "", children}) => {
    const containerClass = ['container__50']
    if (textCenter) {
        containerClass.push('text--center')
    }

    return (<div className={containerClass.join(' ')} data-aos={animation}>{children}</div>)
}